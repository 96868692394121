@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Open Sans";
  src: url("./OpenSans-Regular.ttf");
}
* {
  font-family: "Open Sans";
  box-sizing: border-box;
}
:root {
  --theme: #313d2f;
  --active: #728c6b;
}
section {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  width: 80%;
}
div.alert {
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
h1,
p {
  margin: 0;
}
label {
  font-weight: bold;
  span {
    color: red;
  }
}
input {
  outline: none;
  padding: 10px 30px;
  border-radius: 7px;
  border: none;
  background-color: rgba($color: #000000, $alpha: 0.05);
  font-size: 17px;
}
.input {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
.captcha {
  width: 35px;
  height: 30px;
  border-radius: 5px;
  font-size: 15px;
  border: 1px solid #ccc;
  padding: 5px;
}
input[type="number"]::-webkit-inner-spin-button{
  display: none;
}
.none {
  // display: none;
  opacity: 0;
}
#alert{
  position: fixed;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5000000;
}
.contour {
  border-radius: 7px;
  border: solid 2px rgba(0, 0, 0, 0.1);
  padding: 5px;
  width: 150px;
  height: 50px;
  cursor: pointer;
  transition: 0.2s;
}
.contour:hover {
  border: solid 2px var(--active);
  box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.1);
  transform: scale(1.1);
}
input[type="radio"]:checked ~ .contour {
  border: solid 2px var(--active);
  box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.1);
  background-color: var(--active);
  font-weight: bold;
  color: white;
  transform: scale(1.1);
}
.banniere {
  text-align: center;
  padding: 20px;
  color: white;
  background-color: var(--active);
  margin-top: 50px;
  width: 100%;
}

@media screen and (max-width: 700px) {
  header > ul {
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
    justify-content: center;
  }
}
