#navbar {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  gap: 5px;
  align-items: center;
  box-shadow: 0 3px 5px rgba($color: #000000, $alpha: 0.1);
  #logo {
    width: 100px;
    object-fit: contain;
    cursor: pointer;
  }
}
.menu-btn {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.nav-btn {
  color: white;
  font-weight: bolder;
  padding: 10px 15px;
  width: 150px;
  background: var(--active);
  border: none;
  border-radius: 7px;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    background: var(--theme);
  }
}
@media screen and (max-width: 900px) {
  #navbar {
    padding: 20px;
  }
}
@media screen and (max-width: 500px) {
  .menu-btn{
    flex-direction: column;
  }
}
